<template>
  <PageContentLayoutOnly>
    <div class="pb-5">
      <b-button @click.prevent="addServiceType()" type="button" class="is-primary">
        Add Service Type
      </b-button>
    </div>
    <ModernTable
      :filters="filters"
      :rows="vendorServiceTypes"
      :columns="columns"
      :pagination="pagination"
    >
    </ModernTable>
    <Modal :toggle.sync="toggle">
      <VendorServiceTypeModal :toggle="toggle" :serviceType="selectedVendorServiceType">
      </VendorServiceTypeModal>
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import VendorServiceTypeModal from './components/VendorServiceTypeModal'
import Modal from '@/components/Modal'
import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'VendorServiceTypes',
  components: {
    PageContentLayoutOnly,
    ModernTable,
    VendorServiceTypeModal,
    Modal
  },

  data,

  computed: {
    ...mapGetters('user', ['authUser']),
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {
    hoaId() {
      this.reload()
    },

    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  mounted() {
    this.reload()
  },

  methods,

  i18n: {
    messages: {
      en: { vendorServiceTypes: { title: 'Vendor Service Types' } }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

@media screen and (max-width: $tablet) {
  .filter-button {
    display: none;
  }
}

div.amountFrom {
  width: 60px !important;
}

.filter-button {
  color: #838383 !important;

  &.active {
    color: $link !important;
  }
}

.button.is-ghost {
  background: none;
  border-color: transparent;
  color: #7957d5;
  text-decoration: none;
}
</style>
