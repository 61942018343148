<template>
  <div vendor-service-modal>
    <div class="is-size-4" v-if="formData">{{ buttonText }} Service Type</div>
    <ValidationObserver ref="formGroup" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(submitSurvey)">
          <fieldset>
            <div class="form-grid">
              <div class="column is-12">
                <div
                  v-if="serviceTypes && serviceTypes != undefined"
                  class="column is-12"
                  style="padding:10px; white-space:nowrap;"
                >
                  <label class="label"
                    >Select Service Type<Info
                      title="Title"
                      information="The type of vendor service."
                  /></label>
                  <valid-select
                    placeholder="Service Type"
                    vid="vendorServiceTypeID"
                    v-model="formData.vendorServiceTypeID"
                    aria-required="The service type is required"
                    rules="required"
                  >
                    <option
                      v-for="option in serviceTypes"
                      :value="option.value"
                      :key="option.value"
                      >{{ option.label }}</option
                    >
                  </valid-select>
                </div>
                <div class="pt-5">
                  <button
                    type="submit"
                    tests-id="survey-modal-button"
                    :disabled="loading"
                    class="button is-primary is-rounded"
                  >
                    {{ buttonText }}
                  </button>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <ValidationProvider vid="providerErrors" v-slot="{ errors }">
              <div class="field">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </fieldset>
        </form>
      </transition>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import Info from '@/components/icons/Info'
import ValidSelect from '@/components/inputs/ValidSelect'

export default {
  props: {
    serviceType: Object
  },

  components: {
    ValidSelect,
    ValidationObserver,
    ValidationProvider,
    Info
  },

  mounted() {
    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,
  methods
}
</script>
