<template>
  <div profile-image-modal id="profileImageModal" class="section uploader">
    <div class="container">
      <div class="is-size-4">{{ `${title} User Profile Image` }}</div>
      <div class="box is-12">
        <div class="box is-8">
          <section>
            <div id="viewpdf" v-if="base64pdf" class="has-text-centered">
              <div>
                <div>
                  <div v-dragscroll="true" :class="['doc-viewer', { 'zoom-active': scale > 0.9 }]">
                    <pdf
                      style="display: none;"
                      :src="base64pdf"
                      @num-pages="pageCount = new Array($event)"
                    />
                    <!-- Actual document -->
                    <pdf
                      v-for="(page, index) in pageCount"
                      :key="index"
                      :src="`${base64pdf}`"
                      :page="index + 1"
                      :scale="scale"
                      :resize="true"
                      :style="{ margin: 'auto', width: `${100 * scale}%` }"
                    />
                  </div>
                </div>
                <div class="doc-controls has-text-center">
                  <b-field position="is-centered">
                    <div class="control">
                      <b-button @click="zoom(-1)">-</b-button>
                    </div>
                    <div class="control">
                      <b-button @click="zoom(1)">+</b-button>
                    </div>
                  </b-field>
                </div>
              </div>
            </div>
            <div v-else-if="imageContent">
              <img :src="imageContent" />
            </div>
            <div>
              <!-- Show the Upload Form -->
              <b-field class="file">
                <b-upload
                  v-model="file"
                  drag-drop
                  accept="image/png, image/jpeg, image/jpg"
                  class="rectangle"
                >
                  <section class="section">
                    <div class="">
                      <div>
                        <img
                          class="Fill-1-Copy-11"
                          src="@/assets/images/fill-1-copy-11.svg"
                          alt="Upload"
                        />
                      </div>
                      <div v-if="file">
                        {{ file.name }}
                      </div>
                      <div v-else>
                        <div class="Drag-Drop-your-fil">
                          <div class="text-style-1">Drag & Drop</div>
                          <div>your files here to upload</div>
                        </div>
                      </div>
                    </div>
                  </section>
                </b-upload>
              </b-field>
            </div>
            <div class="pt-5">
              <span class="pr-5" v-if="attachment == false && profileImageDocumentID <= 0"
                ><b-button type="is-primary" :disabled="!file" @click="submitFile"
                  >Upload</b-button
                ></span
              >
              <span class="pr-5" v-if="profileImageDocumentID > 0"
                ><b-button type="is-primary" :disabled="!file" @click="replaceFile"
                  >Replace</b-button
                ></span
              >
              <span><b-button type="is-primary" @click="closeModal">Close</b-button></span>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { dragscroll } from 'vue-dragscroll'

export default {
  name: 'ProfileImageModal',
  props: {
    profileImageDocument: Object
  },

  directives: { dragscroll },

  components: {},

  mounted() {
    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,
  methods
}
</script>
<style lang="scss">
#profileImageModal {
  .modal-h-full {
    left: 50%;
    transform: translate(-50%);
  }

  .doc-viewer {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 130%;
    min-height: 40vh;
    max-height: calc(100vh - 320px);
    border: 1px solid #dbdbdb;

    &.zoom-active {
      cursor: grab;
    }
  }

  .doc-controls {
    margin-top: -1em;
  }
}

#request-image-upload {
  .upload .upload-draggable {
    border: none 0;
    border-radius: 0;
    width: 100%;
    padding: 0;
  }

  .modal-close,
  .delete {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    margin: 5px 5px 0 0;
  }
}

.uploader {
  .rectangle {
    width: 244px;
    height: 137px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0 0px;
    border-radius: 4px;
  }
  .Drag-Drop-your-fil {
    width: 192px;
    height: 34px;
    margin: 7px 0 8px 4px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center !important;
    color: #838383;
  }

  .Drag-Drop-your-fil .text-style-1 {
    font-weight: bold;
  }

  img.Fill-1-Copy-11 {
    width: 24px;
    height: 30px;
    margin: 0 85px 7px 83px;
    object-fit: contain;
  }
}
</style>
