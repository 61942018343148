import moment from 'moment'

export const data = () => ({
  toggle: false,
  loading: true,
  isDebug: true,
  formatDateFn: function(value) {
    return value && value != undefined
      ? moment(value.toString(), 'YYYY/MM/DD HH:mm:ss')
          .format('MM-DD-YYYY')
          .toString()
      : ''
  }
})
