import { vendorServiceTypeStore } from '@/services/VendorServiceType/store'
import $ from 'jquery'
import { notifyError } from '@/services/notify'

export const methods = {
  async reload() {
    this.loading = true

    await this.loadServiceTypes()

    console.debug('serviceType=' + JSON.stringify(this.serviceType))

    if (this.serviceType && this.serviceType != undefined) {
      //Update
      this.buttonText = 'Save'

      this.formData.vendorServiceTypeID = this.serviceType.vendorServiceTypeID

      this.loading = false
    } else {
      this.buttonText = 'Add'

      this.loading = false
    }

    this.loading = false
  },

  open() {
    this.formData = {
      serviceTypeID: 0
    }

    this.loading = false
  },

  async loadServiceTypes() {
    await vendorServiceTypeStore.dispatch('getVendorServiceTypeList').then(({ list }) => {
      if (list) {
        console.log('list=' + JSON.stringify(list))
        this.serviceTypes = list.map(m => ({
          value: m.vendorServiceTypeID,
          label: m.name
        }))
      }
    })
  },

  async submitSurvey() {
    if (this.serviceType && this.serviceType != undefined) {
      await this.updateServiceType()
    } else {
      await this.addServiceType()
    }
  },

  async addServiceType() {
    if (!this.formData.vendorServiceTypeID || this.formData.vendorServiceTypeID == undefined) {
      notifyError('There was a problem processing this service type.')
      return
    }

    this.loading = true

    await vendorServiceTypeStore.dispatch('addVendorGlobalServiceType', {
      vendorServiceTypeID: this.formData.vendorServiceTypeID,
      vendorGlobalID: this.authUser.vendorGlobalID,
      done: () => {
        console.debug('added service type...')
        this.$emit('update:toggle', (this.toggle = false))
        $('#clickclose').click()
        this.loading = false
      }
    })
  },

  async updateServiceType() {
    if (!this.serviceType || this.serviceType == undefined || !this.formData.vendorServiceTypeID) {
      notifyError('There was a problem updating this service type.')
      return
    }

    const payload = {
      vendorGlobalServiceTypeID: this.serviceType.vendorGlobalServiceTypeID,
      vendorServiceTypeID: this.formData.vendorServiceTypeID,
      vendorGlobalID: this.serviceType.vendorGlobalID
    }

    await vendorServiceTypeStore.dispatch('updateVendorGlobalServiceType', {
      payload: payload,
      done: () => {
        // auto-close modal
        this.$emit('update:toggle', (this.toggle = false))
        $('#clickclose').click()
        this.loading = false
      }
    })
  }
}
