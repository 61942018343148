<template>
  <div two-factor-setup>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
          <fieldset>
            <div class="form-grid">
              <div class="column is-12">
                <label class="label"
                  >Primary Authentication Method<Info
                    title="Primary Authentication Method"
                    information="This authentication will be another layer of extra security after you enter your password. It will validate your login with another level of security that is not linked to your password. The following options will allow you to choose how that security will be implemented."
                /></label>
                <valid-select
                  :disabled="setForRemoval === true ? true : false"
                  placeholder="Primary Authentication Method"
                  vid="primaryTwoFactorMethod"
                  v-model="formData.primaryTwoFactorMethod"
                  required
                >
                  <option
                    v-for="option in twoFactorTypes"
                    :value="option.value"
                    :key="option.value"
                    >{{ option.label }}</option
                  >
                </valid-select>
              </div>
              <div v-if="formData.primaryTwoFactorMethod === 'SMSCode'" class="column is-12">
                <valid-input
                  :disabled="setForRemoval === true ? true : false"
                  name="PhoneNumber"
                  label="Phone Number"
                  maxlength="400"
                  type="text"
                  vid="phoneNumber"
                  placeholder="Phone Number"
                  aria-label="Phone Number"
                  rules="required:max:400"
                  v-model="formData.phoneNumber"
                />
                <div class="columns">
                  <div class="column is-1">
                    <b-checkbox
                      class="has-left-text"
                      v-model="formData.phoneNumberConfirmation"
                    ></b-checkbox>
                  </div>
                  <div class="column is-11">
                    I confirm that by selecting this authentication method, I am consenting to allow
                    HOA My Way LLC to send messages to the above phone number via SMS/text for the
                    purposes of authentication and notifications. Message and data rates may apply.
                    To opt out of this service, return to this page and disable Additional
                    Authentication or select a different method.
                    <a href="https://hoamyway.com/privacy-policy" target="_blank">Privacy Policy</a>
                    and
                    <a href="https://hoamyway.com/portal-terms-and-conditions" target="_blank"
                      >Terms</a
                    >
                  </div>
                </div>
              </div>
              <div v-if="formData.primaryTwoFactorMethod === 'EmailCode'" class="column is-12">
                <valid-input
                  :disabled="setForRemoval === true ? true : false"
                  name="EmailAddress"
                  label="Email Address"
                  maxlength="400"
                  type="text"
                  vid="emailAddress"
                  placeholder="Email Address"
                  aria-label="Email Address"
                  rules="required|max:400"
                  v-model="formData.emailAddress"
                />
              </div>
              <div
                class="readonly column is-12"
                v-if="
                  imageContent &&
                    formData.primaryTwoFactorMethod === 'AuthenticatorCode' &&
                    setForRemoval === false
                "
              >
                <div>
                  <valid-input
                    aria-readonly="true"
                    aria-disabled="true"
                    disabled="true"
                    name="manualEntryKey"
                    label="Manual Entry Key"
                    type="text"
                    vid="manualEntryKey"
                    placeholder="Manual Entry Key"
                    aria-label="Manual Entry Key"
                    v-model="formData.manualEntryKey"
                  />
                </div>
                <div>
                  <img :src="imageContent" />
                </div>
                <div class="column is-12">
                  <valid-input
                    style="width:150px !important;"
                    :disabled="codeValidated === true ? true : false"
                    name="checkCode"
                    label="Check Code"
                    type="text"
                    vid="checkCode"
                    placeholder="Check Code"
                    aria-label="Check Code"
                    rules="required|max:400"
                    v-model="formData.checkAuthenticatorCode"
                  />&nbsp;
                </div>
                <div class="p-2 has-text-info-dark" v-if="codeValidationStatus != ''">
                  {{ codeValidationStatus }}
                </div>
                <div class="pl-2">
                  <button
                    :disabled="codeValidated === true ? true : false"
                    @click.prevent="checkAuthCode(formData.checkAuthenticatorCode), ''"
                    class="button is-light"
                  >
                    Check
                  </button>
                </div>
              </div>
              <div class="column is-12">
                <label class="label">Secondary Authentication Method</label>
                <valid-select
                  :disabled="setForRemoval === true ? true : false"
                  placeholder="Secondary Authentication Method"
                  vid="secondaryTwoFactorMethod"
                  v-model="formData.secondaryTwoFactorMethod"
                >
                  <option
                    v-for="option in twoFactorSecondaryTypes"
                    :value="option.value"
                    :key="option.value"
                    >{{ option.label }}</option
                  >
                </valid-select>
              </div>
              <div v-if="formData.secondaryTwoFactorMethod === 'SMSCode'" class="column is-12">
                <valid-input
                  :disabled="setForRemoval === true ? true : false"
                  name="PhoneNumber"
                  label="Phone Number"
                  maxlength="400"
                  type="text"
                  vid="phoneNumber"
                  placeholder="Phone Number"
                  aria-label="Phone Number"
                  rules="required:max:400"
                  v-model="formData.phoneNumberSecondary"
                />
                <div class="columns">
                  <div class="column is-1">
                    <b-checkbox
                      class="has-left-text"
                      v-model="formData.phoneNumberSecondaryConfirmation"
                    ></b-checkbox>
                  </div>
                  <div class="column is-11">
                    I confirm that by selecting this authentication method, I am consenting to allow
                    HOA My Way LLC to send messages to the above phone number via SMS/text for the
                    purposes of authentication and notifications. Message and data rates may apply.
                    To opt out of this service, return to this page and disable Additional
                    Authentication or select a different method.
                    <a href="https://hoamyway.com/privacy-policy" target="_blank">Privacy Policy</a>
                    and
                    <a href="https://hoamyway.com/portal-terms-and-conditions" target="_blank"
                      >Terms</a
                    >
                  </div>
                </div>
              </div>
              <div v-if="formData.secondaryTwoFactorMethod === 'EmailCode'" class="column is-12">
                <valid-input
                  :disabled="setForRemoval === true ? true : false"
                  name="EmailAddress"
                  label="Email Address"
                  maxlength="400"
                  type="text"
                  vid="emailAddress"
                  placeholder="Email Address"
                  aria-label="Email Address"
                  rules="required|max:400"
                  v-model="formData.emailAddressSecondary"
                />
              </div>
              <div
                class="readonly column is-12"
                v-if="
                  imageContent &&
                    formData.secondaryTwoFactorMethod === 'AuthenticatorCode' &&
                    setForRemoval === false
                "
              >
                <div>
                  <valid-input
                    aria-readonly="true"
                    aria-disabled="true"
                    disabled="true"
                    name="manualEntryKey"
                    label="Manual Entry Key"
                    type="text"
                    vid="manualEntryKey"
                    placeholder="Manual Entry Key"
                    aria-label="Manual Entry Key"
                    v-model="formData.manualEntryKey"
                  />
                </div>
                <div>
                  <img :src="imageContent" />
                </div>
                <div class="column is-12">
                  <valid-input
                    style="width:150px !important;"
                    :disabled="codeSecondaryValidated === true ? true : false"
                    name="checkCode"
                    label="Check Code"
                    type="text"
                    vid="checkCode"
                    placeholder="Check Code"
                    aria-label="Check Code"
                    rules="required|max:400"
                    v-model="formData.checkSecondaryAuthenticatorCode"
                  />&nbsp;
                </div>
                <div class="p-2 has-text-info-dark" v-if="codeValidationSecondaryStatus != ''">
                  {{ codeValidationSecondaryStatus }}
                </div>
                <div class="pl-2">
                  <button
                    :disabled="codeSecondaryValidated === true ? true : false"
                    @click.prevent="checkAuthCode('', formData.checkSecondaryAuthenticatorCode)"
                    class="button is-light"
                  >
                    Check
                  </button>
                </div>
              </div>
              <div
                v-if="setForRemoval === false"
                class="column is-12"
                :style="{ display: 'flex', justifyContent: 'end' }"
              >
                <button type="submit" class="button is-primary is-rounded" :disabled="loading">
                  Save
                </button>
              </div>
              <div v-else class="column is-12">
                <button
                  type="button"
                  @click.prevent="removeExistingSetup()"
                  class="button is-primary is-rounded"
                  :disabled="loading"
                >
                  Remove Existing Setup
                </button>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <ValidationProvider vid="providerErrors" v-slot="{ errors }">
              <div class="field">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </fieldset>
        </form>
      </transition>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { twoFactorAuthentication } from '@/services/TwoFactorAuthentication/store'
import { notifyError, notifyMessage } from '@/services/notify'
import Info from '@/components/icons/Info'

// components
import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'

import moment from 'moment'

let DEFAULT_FORM_VALUES = {
  userAdditionalAuthenticationMethodID: 0,
  primaryTwoFactorMethod: '',
  secondaryTwoFactorMethod: '',
  phoneNumber: '',
  phoneNumberConfirmation: false,
  emailAddress: '',
  phoneNumberSecondary: '',
  phoneNumberSecondaryConfirmation: false,
  emailAddressSecondary: '',
  manualEntryKey: '',
  checkAuthenticatorCode: '',
  checkSecondaryAuthenticatorCode: ''
}

export const defaultDateFormat = 'MM/dd/yyyy'

/*
  <TwoFactorSetup />
*/
export default {
  props: {
    scope: String,
    fromFirstLogin: Boolean
  },
  data() {
    return {
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      isDebug: true,
      isDebug2: false,
      codeValidated: false,
      codeSecondaryValidated: false,
      codeValidationStatus: '',
      codeValidationSecondaryStatus: '',
      setForRemoval: false,
      removalList: [],
      imageContent: null,
      imageFile: null,
      twoFactorTypes: [],
      twoFactorSecondaryTypes: []
    }
  },
  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider,
    ValidSelect,
    Info
  },
  watch: {},
  async mounted() {
    this.reload()
  },

  methods: {
    async reload() {
      await this.loadTwoFactorTypes()
      await this.loadAuthenticatorQrCode()
      await this.loadExistingAuthentication()

      this.codeValidated = false
      this.codeSecondaryValidated = false
    },

    async loadExistingAuthentication() {
      let hasPrimary = false
      let hasSecondary = false

      await twoFactorAuthentication.dispatch('getExistingAuthenticationList').then(({ list }) => {
        if (list) {
          this.removalList = list
          Array.from(list).forEach(element => {
            if (element) {
              if (element.priority && element.priority === 'Primary' && hasPrimary === false) {
                this.formData.primaryTwoFactorMethod = element.methodType || ''
                this.formData.userAdditionalAuthenticationMethodID =
                  element.userAdditionalAuthenticationMethodID || 0

                if (element.methodType === 'SMSCode') {
                  this.formData.phoneNumber = element.authenticationData || ''
                } else if (element.methodType === 'EmailCode') {
                  this.formData.emailAddress = element.authenticationData || ''
                }

                this.setForRemoval = true

                hasPrimary = true
              }

              if (element.priority && element.priority === 'Secondary' && hasSecondary === false) {
                this.formData.secondaryTwoFactorMethod = element.methodType || ''
                this.formData.userAdditionalAuthenticationMethodID =
                  element.userAdditionalAuthenticationMethodID || 0

                if (element.methodType === 'SMSCode') {
                  this.formData.phoneNumberSecondary = element.authenticationData || ''
                } else if (element.methodType === 'EmailCode') {
                  this.formData.emailAddressSecondary = element.authenticationData || ''
                }

                this.setForRemoval = true

                hasPrimary = true
              }
            }
          })
        }
      })
    },

    async loadTwoFactorTypes() {
      await twoFactorAuthentication.dispatch('loadTwoFactorTypes').then(({ list }) => {
        if (list) {
          this.twoFactorTypes = list
          this.twoFactorSecondaryTypes = list
        }
      })
    },

    async loadAuthenticatorQrCode() {
      if (!this.formData) {
        if (this.isDebug == true) console.debug('problem with loadAuthenticatorQrCode...')
        return
      }

      try {
        await twoFactorAuthentication.dispatch('getAuthenticatorQrCode', {}).then(({ result }) => {
          if (this.isDebug2 == true)
            console.debug(' - this.imageFile=' + JSON.stringify(result.qrCodeBase64))

          if (result && result.qrCodeBase64 && result.qrCodeBase64 != undefined) {
            this.imageContent = `data:image/png;base64,${result.qrCodeBase64}`
            this.formData.manualEntryKey = result.manualEntryKey
          }
        })
      } catch (e) {
        if (this.isDebug == true) console.debug(e)
      }
    },

    async checkAuthCode(primaryCode, secondaryCode) {
      if (primaryCode === '' && secondaryCode === '') {
        if (primaryCode !== '') {
          this.codeValidated = false
          this.codeValidationStatus = ''
        }

        if (secondaryCode !== '') {
          this.codeSecondaryValidated = false
          this.codeValidationSecondaryStatus = ''
        }
        notifyError('Please enter a value for the authenticator code to check.')
        return
      }

      const myCode = primaryCode && primaryCode.length > 0 ? primaryCode : secondaryCode

      try {
        await twoFactorAuthentication
          .dispatch('checkAuthenticatorCode', { code: myCode })
          .then(({ result }) => {
            if (result && result === true) {
              if (primaryCode !== '') {
                this.codeValidated = true
                this.codeValidationStatus = 'Successful code validation.'
              }

              if (secondaryCode !== '') {
                this.codeSecondaryValidated = true
                this.codeValidationSecondaryStatus = 'Successful code validation.'
              }
            } else {
              if (primaryCode !== '') {
                this.codeValidated = false
                this.codeValidationStatus = 'Failed code validation.'
              }

              if (secondaryCode !== '') {
                this.codeSecondaryValidated = false
                this.codeValidationSecondaryStatus = 'Failed code validation.'
              }
            }
          })
      } catch (e) {
        if (this.isDebug == true) console.debug(e)
      }
    },

    async onFormSubmit() {
      if (this.isDebug === true) console.debug('in onFormSubmit()...')

      if (
        !this.formData ||
        (!this.formData.primaryTwoFactorMethod && !this.formData.secondaryTwoFactorMethod)
      ) {
        notifyError('Please select an authentication type.')
        return
      }

      let params = {}

      if (this.formData.primaryTwoFactorMethod) {
        if (this.formData.primaryTwoFactorMethod === 'SMSCode') {
          params.authenticationData = this.formData.phoneNumber || ''
          if (!this.formData.phoneNumberConfirmation) {
            notifyError(
              'Please check the SMS consent checkbox for your primary authentication method to continue.'
            )
            return
          }
        } else if (this.formData.primaryTwoFactorMethod === 'EmailCode') {
          params.authenticationData = this.formData.emailAddress || ''
        } else if (this.formData.primaryTwoFactorMethod === 'AuthenticatorCode') {
          if (this.codeValidated == false) {
            notifyError(
              'Please press "Check" to validate your Primary authenticator app code first. Then press "Submit".'
            )
            return
          }
          params.authenticationData = this.formData.checkAuthenticatorCode
        }

        params.methodType = this.formData.primaryTwoFactorMethod
        params.priority = 'Primary'

        try {
          await twoFactorAuthentication.dispatch('addAuthentication', {
            methodType: params.methodType,
            authenticationData: params.authenticationData,
            priority: params.priority,
            done: async ({ details }) => {
              if (details && details.userAdditionalAuthenticationMethodID) {
                notifyMessage(
                  `Successfully added your Primary multi-factor authentication setup for: ${this.formData.primaryTwoFactorMethod}.`
                )

                //Update first login flag
                try {
                  await twoFactorAuthentication.dispatch('saveFirstLoginMultiFactor', {
                    firstLoginMultiFactor: true || false,
                    done: () => {}
                  })
                } catch (e) {
                  notifyError(e)
                }
              }
            }
          })
        } catch (e) {
          notifyError('There was an issue adding your primary multi-factor setup.')
        }
      }

      if (this.formData.secondaryTwoFactorMethod) {
        if (this.formData.secondaryTwoFactorMethod === 'SMSCode') {
          params.authenticationData = this.formData.phoneNumberSecondary || ''
          if (!this.formData.phoneNumberSecondaryConfirmation) {
            notifyError(
              'Please check the SMS consent checkbox for your secondary authentication method to continue.'
            )
            return
          }
        } else if (this.formData.secondaryTwoFactorMethod === 'EmailCode') {
          params.authenticationData = this.formData.emailAddressSecondary || ''
        } else if (this.formData.secondaryTwoFactorMethod === 'AuthenticatorCode') {
          if (this.codeSecondaryValidated == false) {
            notifyError(
              'Please press "Check" to validate your Secondary authenticator app code first. Then press "Submit".'
            )
            return
          }
          params.authenticationData = this.formData.checkSecondaryAuthenticatorCode
        }

        params.methodType = this.formData.secondaryTwoFactorMethod
        params.priority = 'Secondary'

        try {
          twoFactorAuthentication.dispatch('addAuthentication', {
            methodType: params.methodType,
            authenticationData: params.authenticationData,
            priority: params.priority,
            done: ({ details }) => {
              if (details && details.userAdditionalAuthenticationMethodID) {
                notifyMessage(
                  `Successfully added your Secondary multi-factor authentication setup for: ${this.formData.secondaryTwoFactorMethod}.`
                )
              }
            }
          })
        } catch (e) {
          notifyError('There was an issue adding your secondary multi-factor setup.')
        }
      }

      this.reload()
    },

    async removeExistingSetup() {
      if (
        !this.formData ||
        !this.formData.userAdditionalAuthenticationMethodID ||
        this.formData.userAdditionalAuthenticationMethodID <= 0 ||
        !this.removalList
      ) {
        notifyError('There was an issue removing the existing multi-factor authentication setup.')
        return
      }

      try {
        Array.from(this.removalList).forEach(element => {
          if (element && element.userAdditionalAuthenticationMethodID) {
            twoFactorAuthentication.dispatch('deleteExistingAuthentication', {
              userAdditionalAuthenticationMethodID: element.userAdditionalAuthenticationMethodID,
              done: () => {}
            })
          }
        })

        notifyMessage(
          'Your existing multi-factor authentication setup has been removed. Plese enter a new setup.'
        )
        this.loading = false

        this.formData = { ...DEFAULT_FORM_VALUES }
        this.$refs.form.reset()

        this.removalList = []
        this.setForRemoval = false

        this.reload()
      } catch (e) {
        notifyError('There was an issue removing the existing multi-factor authentication setup.')
      }
    }
  }
}
</script>
