<template>
  <div role="region" twofactorsetup>
    <section class="section card">
      <div class="level pl-5 pr-5">
        <TwoFactorSetup />
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TwoFactorSetup from '@/components/TwoFactorSetup'

import { data } from './keys/data'
import { methods } from './keys/methods'

export default {
  components: {
    TwoFactorSetup
  },

  data,

  computed: {
    ...mapState({
      unit: state => state.hoa.unit
    })
  },

  watch: {
    hoaId() {
      this.reload()
    }
  },

  mounted() {
    this.reload()
  },

  methods,

  i18n: {
    messages: {
      en: { twoFactorSetup: { detailsTitle: 'Two Factor Authentication' } }
    }
  }
}
</script>

<style lang="scss" scoped>
.owner-avatar {
  margin: 1em auto 2em;
}

.card {
  box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.05) !important;

  .card {
    border: 0 !important;
  }
}

.avatar-img {
  border: 1px solid #e7e8f2;
  padding: 0.25em;
}

.address-card {
  h5,
  h6 {
    margin: 0;
  }
}

.details-img {
  img {
    border-radius: 0.5em;
  }
}
</style>
