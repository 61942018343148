import Button from '@/components/buttons/Button'
import { vendorServiceTypeStore } from '@/services/VendorServiceType/store'
import { notifyError } from '@/services/notify'
import _get from 'lodash/get'

export const methods = {
  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  confirmDeleteVendorServiceType(vendorServiceTypeID, name) {
    console.debug('delete vendor service type')
    this.$buefy.dialog.confirm({
      title: 'Deleting Vendor Service Type',
      message: `Are you sure you want to <b>delete</b> the Vendor Service Type for "${name}"?`,
      confirmText: 'Delete Vendor Service Type',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteVendorServiceType(vendorServiceTypeID)
    })
  },

  async deleteVendorServiceType(id) {
    if (this.isDebug == true) console.debug('...in deleteVendorServiceType id=' + id)

    if (id <= 0) {
      notifyError('There was a problem deleting this vendor service type.')
      return
    }

    try {
      await vendorServiceTypeStore.dispatch('deleteVendorGlobalServiceType', {
        vendorGlobalServiceTypeID: id,
        done: () => {
          this.reload()
        }
      })
    } catch (e) {
      console.debug('error in method for deleting the vendor service type.:' + e)
    }

    this.loading = false
  },

  addServiceType() {
    this.toggle = true
  },

  determineServiceTypes(list) {
    if (this.isDebug == true) console.debug('list()...' + JSON.stringify(list))

    if (list) {
      let vendorGlobalServiceTypeID = 0
      let serviceType = ''
      let description = ''

      this.vendorServiceTypes = list.map(i => {
        vendorGlobalServiceTypeID = _get(i, 'vendorGlobalServiceTypeID', 0)
        serviceType = _get(i, 'vendorServiceType.name', '')
        description = _get(i, 'vendorServiceType.description', '')

        return {
          serviceType,
          description,
          edit: {
            component: Button,
            props: {
              onClick: () => {
                this.processUpdate(i)
              },
              text: 'Edit'
            }
          },
          erase: {
            component: Button,
            props: {
              onClick: () => {
                this.confirmDeleteVendorServiceType(vendorGlobalServiceTypeID, serviceType)
              },
              text: 'Delete'
            }
          }
        }
      })
    } else {
      console.log('else determineServiceTypes...')
    }
  },

  async reload() {
    this.loading = true

    try {
      await vendorServiceTypeStore
        .dispatch('getVendorGlobalServiceTypeById', {
          vendorGlobalID: this.authUser.vendorGlobalID
        })
        .then(({ list }) => {
          if (list) {
            this.determineServiceTypes(list)
          }
        })
    } catch (e) {
      console.debug('error in method for retrieving notification subscription:' + e)
    }

    this.loading = false
  },

  processUpdate(serviceType) {
    console.log('processUpdate=' + JSON.stringify(serviceType))
    this.toggle = true
    this.selectedVendorServiceType = serviceType
  }
}
