/*
  import { profileImageStore } from '@/services/UserProfileImage/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import { notifyError, notifyProblem } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  async getUserProfileId({}) {
    try {
      console.debug('in getUserProfileId...')
      const refreshedUser = await kms.get('/User')

      if (isDebug == true) console.debug('getUserProfileId=' + JSON.stringify(refreshedUser))

      return {
        result: refreshedUser
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the user.`)
      console.error(exception)
    }

    return {
      result: {}
    }
  },

  async getProfileImageFile({ dispatch }, { params, done }) {
    const path = `/User/GetProfileImage`
    const result = await kms.get(path, {
      params
    })

    if (this.isDebug == true) console.debug('getProfileImageFile=' + JSON.stringify(result))

    if (dispatch && dispatch != undefined) console.debug(dispatch)

    if (result) {
      console.debug(`Successfully retrieved the user profile image.`)
      done({ details: result })
    } else {
      console.error('There was a problem retrieving the user profile image.')
    }
  },

  async uploadProfileImage({ dispatch }, { payload = {}, done }) {
    const formData = new FormData()
    formData.append('file', payload.file)

    try {
      const path = `/User/StoreProfileImage`
      const uploadedProfileImageFile = await kms.post(path, formData, {
        'Content-Type': 'multipart/form-data'
      })

      console.debug('uploadProfileImage path=' + path)

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (isDebug == true)
        console.debug('uploadProfileImage with payload=' + JSON.stringify(uploadedProfileImageFile))

      done({ returnedFile: uploadedProfileImageFile })
    } catch (e) {
      notifyError(e)
    }
  },

  async removeProfileImage({ dispatch }, { done }) {
    try {
      const path = `/User/RemoveIdentityProfileImage`
      const success = await kms.post(path)

      console.debug('removeProfileImage=' + success)

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      done({ result: success })
    } catch (e) {
      notifyError(e)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const profileImageStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
