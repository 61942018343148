/*
  import { vendorServiceTypeStore } from '@/services/VendorServiceType/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import _get from 'lodash/get'
import { notifyProblem, notifyMessage, notifyError } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await vendorServiceTypeStore.dispatch ('getVendorServiceTypeList');
  */

  async getVendorServiceTypeList({}) {
    console.log('in getVendorServiceTypeList...')
    try {
      const results = await kms.get(`/Vendors/VendorServiceType/List`)

      if (isDebug == true)
        console.debug('getVendorServiceTypeList log=' + JSON.stringify(results.results))

      return {
        list: results.results
      }
    } catch (exception) {
      notifyProblem(`The Vendor Service Type list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getVendorGlobalServiceTypeById({}, { vendorGlobalID }) {
    try {
      console.debug('in getVendorGlobalServiceTypeById...' + vendorGlobalID)
      const result = await kms.get(`/Vendors/VendorGlobalServiceType/List`, {
        params: {
          vendorGlobalID: vendorGlobalID
        }
      })

      if (isDebug == true)
        console.debug('getVendorGlobalServiceTypeById=' + JSON.stringify(result.results))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the global service type list for this vendor.`)
      console.error(exception)
    }

    return {
      list: {}
    }
  },

  async addVendorGlobalServiceType({ dispatch }, { vendorServiceTypeID, vendorGlobalID, done }) {
    if (isDebug == true) console.debug('...in addVendorGlobalServiceType')
    try {
      const results = await kms.post(`/Vendors/VendorGlobalServiceType`, {
        vendorServiceTypeID,
        vendorGlobalID
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.vendorGlobalServiceTypeID > 0) {
        notifyMessage(`Successfully added the vendor service type.`)
        done()
      } else {
        notifyError('There was a problem adding this vendor service type.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this vendor service type.`)
      console.error(exception)
    }
  },

  async updateVendorGlobalServiceType({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/Vendors/VendorGlobalServiceType`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.vendorGlobalServiceTypeID > 0) {
        notifyMessage(`Successfully updated the vendor service type.`)
        done()
      } else {
        notifyError('There was a problem updating the vendor service type.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem processing the update for the vendor service type.`)
      console.error(exception)
    }
  },

  async deleteVendorGlobalServiceType({ dispatch }, { vendorGlobalServiceTypeID, done }) {
    try {
      const path = `/Vendors/VendorGlobalServiceType/${vendorGlobalServiceTypeID}?forceDelete=false`

      const results = await kms.delete(path)

      if (isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted the vendor service type.`)
        done()
      } else {
        notifyError('There was a problem deleting the vendor service type.')
      }
    } catch (exception) {
      if (exception) {
        console.error(exception.message)
      }
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const vendorServiceTypeStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
