import { mapActions } from 'vuex'

export const methods = {
  ...mapActions('user', ['selectHoaId']),

  async reload() {
    this.loading = true
    this.loading = false
  },

  setChangePasswordModal() {
    this.toggle = true
  }
}
