<template>
  <div class="section">
    <div class="container">
      <div
        class="box is-12"
        v-if="selectedSubscriptionAlert && selectedSubscriptionAlert != undefined"
      >
        <div class="is-size-4">Edit Notification Subscription</div>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div class="column is-12">
                  <valid-input
                    name="Notification Type"
                    disabled="true"
                    label="Notification Type"
                    type="text"
                    vid="AlertType"
                    placeholder="Notification Type"
                    spellcheck="true"
                    aria-label="Notification Type"
                    rules="required"
                    class="is-small"
                    v-model="formData.AlertType"
                  />
                </div>
                <b-field class="pl-3 pt-4" label="Notification Delivery Types">
                  <div
                    class="block column is-12"
                    v-if="
                      selectedSubscriptionAlert &&
                        selectedSubscriptionAlert != undefined &&
                        selectedSubscriptionAlert.supportedDeliveryNameCsvs &&
                        selectedSubscriptionAlert.supportedDeliveryNameCsvs != undefined
                    "
                  >
                    <div
                      class="block column is-12"
                      v-for="(item, index) in selectedSubscriptionAlert.supportedDeliveryNameCsvs"
                      :key="index"
                    >
                      <b-checkbox
                        :disabled="item.isRequired"
                        v-model="checkboxGroup"
                        :native-value="item.deliveryType"
                      >
                        {{ item.deliveryType }}
                      </b-checkbox>
                    </div>
                  </div>
                </b-field>
                <div class="pb-5">
                  <button type="submit" :disabled="loading" class="button is-link is-light">
                    Save
                  </button>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ValidationError } from '@/services/errors'
import { mapState } from 'vuex'
import $ from 'jquery'
import { notifyError } from '@/services/notify'

// components
import ValidInput from '@/components/inputs/ValidInput'

import moment from 'moment'
//const today = moment()

let DEFAULT_FORM_VALUES = {
  alertType: ''
}

export const defaultDateFormat = 'MM/dd/yyyy'

export default {
  name: 'AlertSubscriptionModal',

  props: {
    selectedSubscriptionAlert: Object
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },

  data: function() {
    return {
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      isDebug: true,
      checkboxGroup: [],
      checkboxGroupCodeNames: []
    }
  },

  created() {},

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  mounted() {
    this.loading = true
    this.checkboxGroup = []

    if (this.selectedSubscriptionAlert && this.selectedSubscriptionAlert.notificationType) {
      if (this.isDebug == true)
        console.debug(
          'this.selectedSubscriptionAlert=' + JSON.stringify(this.selectedSubscriptionAlert)
        )
      this.formData.AlertType = this.selectedSubscriptionAlert.notificationType.name
    }

    this.$store
      .dispatch('alertSubscription/getNotificationDeliveryList', {
        supportsManagementAlerts: true
      })
      .then(() => {
        if (this.selectedSubscriptionAlert && this.selectedSubscriptionAlert.notificationType) {
          try {
            if (
              this.selectedSubscriptionAlert.notificationDeliveryNameCsv &&
              this.selectedSubscriptionAlert.notificationDeliveryNameCsv != undefined &&
              this.selectedSubscriptionAlert.notificationDeliveryNameCsv.length > 0
            ) {
              let split = this.selectedSubscriptionAlert.notificationDeliveryNameCsv.split(',')
              for (let i = 0; i < split.length; i++) {
                var checked = split[i].trim()
                if (this.isDebug == true) console.debug(checked)

                if (checked.length > 0) {
                  this.checkboxGroup.push(checked)
                }
              }
            }
          } catch (e) {
            console.debug(e)
          }
        }
      })

    this.loading = false
  },

  methods: {
    getPayload() {
      if (this.isDebug == true) console.debug('this.formData=' + JSON.stringify(this.formData))

      const payload = {
        notificationTypeID:
          this.selectedSubscriptionAlert.notificationType &&
          this.selectedSubscriptionAlert.notificationType != undefined
            ? this.selectedSubscriptionAlert.notificationType.notificationTypeID
            : 0,
        hoaID: this.hoaId ? this.hoaId : 0,
        notificationDeliveryTypes: this.checkboxGroup
      }

      if (this.isDebug == true) console.debug('payload=' + JSON.stringify(payload))

      return payload
    },
    async onFormSubmit() {
      if (
        !this.formData ||
        (this.formData == undefined &&
          this.selectedSubscriptionAlert &&
          this.selectedSubscriptionAlert != undefined &&
          this.selectedSubscriptionAlert.notificationType &&
          this.selectedSubscriptionAlert.notificationType != undefined)
      ) {
        notifyError('There was a problem submitting your subscription notifications.')
        return
      }

      try {
        const payload = this.getPayload()

        this.loading = true

        this.$store.dispatch('alertSubscription/processNotificationSubscriptions', {
          payload: payload,
          done: () => {
            // auto-close modal
            this.$emit('update:toggle', (this.toggle = false))
            $('#clickclose').click()
            this.loading = false
          }
        })
      } catch (e) {
        this.loading = false

        // If this is a validation error, get the details for each field
        if (e instanceof ValidationError) {
          this.$refs.form.setErrors(e.fields)
        }

        notifyError('There was a problem processing your subscription notifications.')
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.step-details {
  background-color: #fff !important;
  padding-left: 0.25rem !important;
  padding-right: 0.5rem !important;
}

.b-steps.is-small .steps {
  margin-top: 1rem;
}

.b-steps .steps + .step-content {
  padding-top: 0 !important;
}

.step-navigation {
  display: flex;
  justify-content: flex-end;
}

.my-grid-class {
  height: 400px;
}

tbody {
  min-height: 100px !important;
  overflow: auto !important;
}
</style>
