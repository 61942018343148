<template>
  <div>
    <div class="is-size-4" v-if="hideTitle && hideTitle == false">Change Password</div>

    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
          <fieldset>
            <div class="form-grid">
              <div class="column is-12">
                <valid-input
                  name="CurrentPassword"
                  label="Current Password"
                  maxlength="8000"
                  type="password"
                  vid="currentPassword"
                  placeholder="Current Password"
                  aria-label="Current Password"
                  rules="required|max:8000"
                  v-model="formData.currentPassword"
                />
              </div>
              <div class="column is-12">
                <valid-input
                  name="NewPassword"
                  label="New Password"
                  maxlength="8000"
                  type="password"
                  vid="newPassword"
                  placeholder="New Password"
                  aria-label="New Password"
                  rules="required|max:8000"
                  v-model="formData.newPassword"
                />
              </div>
              <div class="column is-12">
                <valid-input
                  name="ConfirmNewPassword"
                  label="Confirm New Password"
                  maxlength="8000"
                  type="password"
                  vid="confirmNewPassword"
                  placeholder="Confirm New Password"
                  aria-label="Confirm New Password"
                  rules="required|max:8000"
                  v-model="formData.confirmNewPassword"
                />
              </div>
              <div class="column is-12" :style="{ display: 'flex', justifyContent: 'end' }">
                <button type="submit" class="button is-primary is-rounded" :disabled="loading">
                  Save
                </button>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <ValidationProvider vid="providerErrors" v-slot="{ errors }">
              <div class="field">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </fieldset>
        </form>
      </transition>
    </ValidationObserver>
  </div>
</template>

<script>
// Components
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ValidationError } from '@/services/errors'
import { notifyMessage, notifyError } from '@/services/notify'
import kms from '@/services/kms'
import { mapState, mapGetters } from 'vuex'
import $ from 'jquery'

// components
import ValidInput from '@/components/inputs/ValidInput'
import moment from 'moment'

let DEFAULT_FORM_VALUES = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: ''
}

export const defaultDateFormat = 'MM/dd/yyyy'

export default {
  name: 'ChangePasswordModal',

  props: {
    scope: String,
    hideTitle: Boolean
  },

  data: function() {
    return {
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      isDebug: true,
      selectedRow: null
    }
  },

  created() {},

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    }),
    ...mapGetters('user', ['authUser', 'appId', 'hoaId'])
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },

  mounted() {},

  methods: {
    async confirmPasswordChange() {
      this.$buefy.dialog.confirm({
        title: 'Change Password',
        message: `Are you sure you want to <b>Change your Password</b>?`,
        confirmText: 'Change Password',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => await this.changePassword()
      })
    },

    async changePassword() {
      if (!this.formData || !this.authUser || !this.scope) {
        notifyError('There was a problem submitting your form.')
        return
      }

      try {
        //Confirm current password
        const authenticateDto = {
          email: this.authUser.email,
          password: this.formData.currentPassword,
          newPassword: this.formData.newPassword
        }

        const pathConfirm = `/User/ConfirmThenSetNewPassword`
        const results = await kms.post(pathConfirm, authenticateDto)

        if (results && results != undefined) {
          if (results == false) {
            notifyError('Unable to update your password.')
          }

          if (results == true) {
            notifyMessage(`Successfully updated your password.`)
          } else {
            notifyMessage(`There was a problem updating the password.`)
          }
          this.$emit('update:toggle', (this.toggle = !this.toggle))

          // auto-close modal
          $('#clickclose').click()
        }
      } catch (e) {
        // If this is a validation error, get the details for each field
        if (e instanceof ValidationError) {
          this.$refs.form.setErrors(e.fields)
        }

        notifyError(e)
      }
      this.loading = false
      this.toggle = false
      this.formComplete = true
      this.formData = { ...DEFAULT_FORM_VALUES }
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
    },

    async onFormSubmit() {
      await this.confirmPasswordChange()
    }
  }
}
</script>

<style lang="scss">
.step-details {
  background-color: #fff !important;
  padding-left: 0.25rem !important;
  padding-right: 0.5rem !important;
}

.b-steps.is-small .steps {
  margin-top: 1rem;
}

.b-steps .steps + .step-content {
  padding-top: 0 !important;
}

.step-navigation {
  display: flex;
  justify-content: flex-end;
}

.my-grid-class {
  height: 400px;
}

tbody {
  min-height: 100px !important;
  overflow: auto !important;
}
</style>
