export function data() {
  return {
    formData: {
      vendorServiceTypeID: 0
    },
    serviceTypes: [],
    isDebug: true,
    isOpen: false,
    loading: true,
    isFetching: false,
    openOnFocus: true,
    buttonText: 'Add'
  }
}
